<template>
  <div class="bm_exa_s_list">
    <div class="bm_exa_s_banner">
      <div class="bm_exa_s_video">
        <img
          v-if="data.videoCoverUrl"
          class="img"
          :src="utils.setOssUrl(data.videoCoverUrl)"
          width="100%"
          height="100%"
        />
        <img
          class="play"
          src="@/assets/images/play.svg"
          alt="播放"
          @click="videoShow = true"
        />
      </div>
      <div class="bm_exa_s_banner_main">
        <p class="title">{{ data.titleOne }}</p>
        <img
          v-if="data.titleTwoUrl"
          class="img"
          :src="utils.setOssUrl(data.titleTwoUrl)"
        />
      </div>
      <div class="bm_exa_s_banner_footer">
        <div class="bm_exa_s_group">
          <div
            v-if="
              !isResize &&
                data &&
                data.titleDisposition &&
                data.titleDisposition.length > 4
            "
            class="bm_exa_s_btn left font_family icon-common_arrow_right"
          ></div>
          <div
            v-for="(item, index) in data.titleDisposition || []"
            :key="index"
            class="bm_exa_s_data"
          >
            <h2>
              <template v-if="!Object.is(item.promotionType, '文本')">
                <CountUp :endVal="+item.promotionTitle" />
                {{ Object.is(item.promotionType, '百分比') ? '%' : '' }}
              </template>
              <template v-else>
                {{ item.promotionTitle }}
              </template>
            </h2>
            <p>{{ item.descriptionDetails }}</p>
          </div>
          <div
            v-if="
              !isResize &&
                data &&
                data.titleDisposition &&
                data.titleDisposition.length > 4
            "
            class="bm_exa_s_btn font_family icon-common_arrow_right"
          ></div>
        </div>
      </div>
    </div>
    <div class="bm_exa_s_box js_bm_exa_s_list">
      <p class="bm_exa_s_t">{{ data.titleThreeIntroduce }}</p>
      <div class="bm_exa_s_cell">
        <div class="bm_exa_s_con bm_exa_s_img">
          <video-player
            v-if="data.videoTwoUrl"
            class="video vjs-custom-skin vjs-big-play-centered"
            ref="videoTwo"
            :playsinline="true"
            @play="onPlayerPlay($event)"
            :options="
              playerOptions({
                src: data.videoTwoUrl
              })
            "
          />
        </div>
        <div class="bm_exa_s_con bm_exa_s_text">
          {{ data.introduceText }}
        </div>
      </div>
      <p class="bm_exa_s_t">合作故事</p>
      <ul class="bm_exa_s_ul" v-if="list.length > 0">
        <li
          class="bm_exa_s_every"
          v-for="item in list"
          :key="item.storyId"
          :class="[isResize && 'vishide animate__animated']"
          @click="handleStoryDetailClick"
        >
          <a
            :href="
              `/example/details?id=${item.storyId}&moduleType=${moduleType ||
                modules.moduleType}&source=story`
            "
            target="_blank"
            class="bm_exa_s_link"
          >
            <div class="bm_exa_s_img_box">
              <img
                v-if="item.coverPictureUrl"
                class="bm_exa_s_img"
                :src="utils.setOssUrl(item.coverPictureUrl)"
                alt=""
              />
              <img
                v-if="item.storyVideoUrl"
                class="play"
                src="@/assets/images/play.svg"
                alt="播放"
              />
            </div>
            <div class="bm_exa_s_details_box">
              <p class="bm_exa_s_title" :title="item.storyTitle">
                {{ item.storyTitle }}
              </p>
              <p class="bm_exa_s_desc" :title="item.storyAbstract">
                {{ item.storyAbstract }}
              </p>
            </div>
          </a>
        </li>
      </ul>
      <Nothing v-else class="nothing" />
    </div>
    <div class="bm_exa_s_m_btn">
      <a v-if="!isCurPageTotal" class="more" @click="handlePages">加载更多</a>
    </div>
    <!--免费试用-->
    <FreeBenchmark v-if="isResize" />
    <van-Dialog
      v-model="videoShow"
      :show-confirm-button="false"
      close-on-click-overlay
      class="video-dialog"
    >
      <video-player
        v-if="data.videoOneUrl"
        class="video vjs-custom-skin vjs-big-play-centered"
        ref="videoOne"
        :playsinline="true"
        @play="onPlayerPlay($event)"
        :options="
          playerOptions({
            src: data.videoOneUrl
          })
        "
      />
    </van-Dialog>
  </div>
</template>

<script>
import CountUp from 'vue-countup-v2'
import { videoPlayer } from 'vue-video-player'
import { getSupplierStoryInfoList } from '@/api/product.js'
import module from '@/const/module'
import $ from 'jquery'
import utils from '@/utils/utils'
import metaInfoMixins from '@/utils/mixin/metaInfoMixins'
import FreeBenchmark from '../product/components/freeBenchmark'

export default {
  mixins: [metaInfoMixins],
  components: { CountUp, videoPlayer, FreeBenchmark },
  data() {
    return {
      modules: module.benchmarkStory,
      utils,
      id: '',
      moduleType: '',
      // 总数据
      data: {},
      // 当前分页数据
      list: [],
      // 总数据
      listAll: [],
      // 视频显隐开关
      videoShow: false,
      top: 0,
      isResize: true,
      page: {
        pageNo: 0,
        pageSize: 6,
        total: 0
      }
    }
  },
  computed: {
    // 当前分页数量
    curPageTotal() {
      const data = this.page.pageNo * this.page.pageSize
      return data
    },
    // 前端分页数量比较
    isCurPageTotal() {
      const data = this.curPageTotal >= this.page.total
      return data
    }
  },
  created() {
    this.id = this.$route.query.id
    this.moduleType = this.$route.query.moduleType
    this.getSupplierStoryInfoList()
  },
  mounted() {
    this.getResize()
    this.getListTop()
    this.$nextTick(() => {
      this.handleScroll()
      window.addEventListener('scroll', this.handleScroll)
      window.onresize = () => {
        this.getResize()
        this.removeVishide()
      }
    })
  },
  methods: {
    /**
     * 合作故事点击事件
     */
    handleStoryDetailClick() {
      const params = {
        officialSite_e_title: document.title,
        officialSite_e_source: '合作故事列表'
      }
      this.$sensors.officialSite_StoryDetail(params)
    },
    /**
     * 视频配置
     * @param {Object} params
     */
    playerOptions({ src = '' }) {
      const data = {
        playbackRates: [0.7, 1.0, 1.5, 2.0], // 播放速度
        autoplay: false, // 如果true,浏览器准备好时开始回放。
        muted: true, // 默认情况下将会消除任何音频。
        loop: true, // 导致视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            src: utils.setOssUrl(src), // 路径
            type: 'video/mp4' // 类型
          }
        ],
        poster: `${utils.setOssUrl(
          src
        )}?x-oss-process=video/snapshot,t_100,f_jpg,w_0,h_0,m_fast`, // 你的封面地址
        // width: document.documentElement.clientWidth,
        notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true, // 全屏按钮
          volumePanel: { inline: false }
        }
      }
      return data
    },
    // 播放回调
    onPlayerPlay() {
      this.$sensors.officialSite_WatchVideo({
        officialSite_e_title: document.title
      })
    },
    /**
     * 屏幕大小判断
     */
    getResize() {
      const width = window.innerWidth
      this.isResize = width > 960
    },
    getListTop() {
      this.$nextTick(() => {
        const el = $('.js_bm_exa_s_list')
        let top = el.offset().top
        this.top = top
        window.onresize = () => {
          let top = el.offset().top
          this.top = top
        }
      })
    },
    /**
     * 获取标杆案例数据
     */
    getSupplierStoryInfoList() {
      const params = {
        moduleId: this.id,
        moduleType: this.moduleType || this.modules.moduleType
      }
      getSupplierStoryInfoList(params).then((res) => {
        if (res.success) {
          this.data = res.data
          this.listAll = res.data.cooperativeStoryList
          this.page.total = res.data.cooperativeStoryCount
          this.handlePages()
        }
      })
    },
    /**
     * 前端分页事件
     */
    handlePages() {
      if (this.isCurPageTotal) return
      this.page.pageNo++
      this.list = this.isCurPageTotal
        ? this.listAll
        : this.listAll.filter((v, i) => i < this.curPageTotal)
      this.$nextTick(() => {
        this.handleScroll()
        this.removeVishide()
      })
    },
    handleScroll() {
      utils.handleScroll(
        $('.js_bm_exa_s_list .bm_exa_s_every'),
        (i, el) => {
          setTimeout(() => {
            el.removeClass('vishide')
              .addClass('animate__slideInUp')
              .removeClass('animate__slideOutDown')
          }, i * 100)
        },
        (i, el) => {
          setTimeout(() => {
            el.removeClass('animate__slideInUp').addClass(
              'animate__slideOutDown'
            )
          }, i * 100)
        },
        -220
      )
    },
    removeVishide() {
      let w = $(window).width()
      if (w < 960) {
        $('.js_bm_exa_s_list .bm_exa_s_every').removeClass('vishide')
      } else {
        $('.js_bm_exa_s_list .bm_exa_s_every').addClass('vishide')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.bm_exa_s_banner {
  position: relative;
  height: 574px;
  background-image: url('../../assets/images/bg_default_story.png');
  background-position: center;
  background-size: cover;
  .bm_exa_s_video {
    width: 100%;
    height: 100%;
    position: absolute;
    .video {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      overflow: hidden;
    }
    img {
      object-fit: cover;
    }
  }
  .bm_exa_s_banner_main {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: calc(100% - 132px);
    z-index: 1;
    .title {
      position: absolute;
      top: 25%;
      font-weight: 400;
      font-size: 64px;
      color: #fff;
      line-height: 90px;
      text-align: center;
    }
    .img {
      position: absolute;
      bottom: 30px;
      width: 236px;
      height: 47px;
      overflow: hidden;
    }
  }
  .bm_exa_s_banner_footer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 132px;
    background: rgba(0, 0, 0, 0.2);
    color: #fff;
    text-align: center;
    z-index: 2;
    .bm_exa_s_group {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 1200px;
      height: 100%;
      text-align: center;
      overflow-x: auto;
      &::-webkit-scrollbar {
        display: none;
      }
      .bm_exa_s_btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 100%;
        text-align: center;
        position: absolute;
        background: rgba(255, 255, 255, 0.3);
        backdrop-filter: blur(2px);
        top: 0;
        bottom: 0;
        right: 0;
        font-size: 22px;
        z-index: 3;
        &.left {
          left: 0;
          transform: rotate(180deg);
        }
      }
      .bm_exa_s_data {
        min-width: 25%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background: rgba(0, 0, 0, 0.3);
        flex: 1;
        height: 100%;
        border-right: 1px solid #7b8595;
        &:first-child {
          border-left: 1px solid #7b8595;
        }
        h2 {
          font-weight: bold;
          font-size: 40px;
          line-height: 42px;
        }
        p {
          margin-top: 12px;
          font-weight: 400;
          font-size: 26px;
          line-height: 26px;
          // max-width: 60%;
          // white-space: nowrap;
          // overflow: hidden;
          // text-overflow: ellipsis;
        }
      }
    }
  }
}
.bm_exa_s_list {
  background-color: #fff;
  .bm_exa_s_t {
    font-size: 32px;
    font-weight: 600;
    color: #333;
    line-height: 32px;
    text-align: center;
    font-style: normal;
    margin: 40px 0;
  }
  .bm_exa_s_box {
    padding: 24px;
    width: 100%;
    margin: 0 auto;
    .bm_exa_s_cell {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      border-radius: 16px;
      border: 1px solid #e6e6e6;
      .bm_exa_s_con {
        font-weight: 400;
        font-size: 26px;
        color: #666;
        line-height: 44px;
        text-align: left;
        &.bm_exa_s_img {
          position: relative;
          width: 100%;
          height: 400px;
          border-radius: 16px;
          overflow: hidden;
        }
        &.bm_exa_s_text {
          height: 120px;
          padding: 24px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          word-break: break-all;
        }
      }
    }
    .bm_exa_s_ul {
      display: flex;
      flex-wrap: wrap;
      .bm_exa_s_every {
        margin-top: 24px;
        width: calc(50% - 14px);
        height: 362px;
        margin-left: 24px;
        transition: 0.7s;
        color: #333;
        border-radius: 16px;
        border: 1px solid #e6e6e6;
        overflow: hidden;
        &:first-child {
          margin-top: 0;
        }
        &:nth-child(2) {
          margin-top: 0;
        }
        &:nth-child(2n + 1) {
          margin-left: 0;
        }
        .bm_exa_s_link {
          display: inline-block;
          width: 100%;
          height: 100%;
          color: #333;
          overflow: hidden;
        }
        .bm_exa_s_img_box {
          position: relative;
          width: 100%;
          overflow: hidden;
          .bm_exa_s_img {
            width: 100%;
            height: 190px;
          }
        }
        .bm_exa_s_details_box {
          width: 100%;
          height: calc(100% - 190px);
          padding: 24px;
          .bm_exa_s_title {
            width: 100%;
            font-weight: 600;
            font-size: 26px;
            padding-bottom: 16px;
            text-align: center;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
          .bm_exa_s_desc {
            font-weight: 400;
            font-size: 24px;
            color: #666;
            line-height: 40px;
            text-align: left;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
          }
        }
      }
    }
  }
  .play {
    width: 64px;
    height: 64px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    z-index: 5;
  }
  .bm_exa_s_m_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 36px 0 60px;
    text-align: center;
    .more {
      font-size: 24px;
      font-weight: 400;
      color: #999;
      cursor: pointer;
    }
  }
  .video-dialog {
    width: 100%;
  }
}
@media (min-width: 751px) and (max-width: 960px) {
  .bm_exa_s_banner {
    .bm_exa_s_banner_footer {
      .bm_exa_s_group {
        overflow-x: hidden;
        .bm_exa_s_data {
          min-width: auto;
        }
      }
    }
  }
}

@media (min-width: 961px) {
  .bm_exa_s_banner {
    .bm_exa_s_banner_footer {
      .bm_exa_s_group {
        overflow-x: hidden;
        .bm_exa_s_data {
          min-width: auto;
          h2 {
            font-size: 40px;
            line-height: 48px;
          }
          p {
            font-size: 16px;
            line-height: 22px;
          }
        }
      }
    }
  }
  .bm_exa_s_list {
    .bm_exa_s_t {
      line-height: 45px;
    }
    .bm_exa_s_box {
      padding: 40px 30px;
      width: 1200px;
      .bm_exa_s_cell {
        flex-direction: row;
        border: none;
        margin-bottom: 80px;
        .bm_exa_s_con {
          width: 50%;
          font-size: 20px;
          color: #333;
          line-height: 37px;
          &.bm_exa_s_img {
            margin-right: 80px;
            width: 50%;
            height: auto;
            border-radius: 8px;
          }
          &.bm_exa_s_text {
            height: auto;
            padding: 0;
            display: block;
            -webkit-box-orient: inherit;
          }
        }
      }
      .bm_exa_s_ul {
        .bm_exa_s_every {
          height: 568px;
          color: #000;
          border-radius: 8px;
          &:hover {
            box-shadow: 0px 20px 40px 0px rgba(62, 74, 101, 0.2);
          }
          .bm_exa_s_link {
            color: #000;
          }
          .bm_exa_s_img_box {
            .bm_exa_s_img {
              height: 331px;
            }
          }
          .bm_exa_s_details_box {
            height: calc(100% - 331px);
            padding: 30px 24px;
            .bm_exa_s_title {
              font-size: 28px;
              font-weight: 600;
              padding-bottom: 30px;
              color: rgba(0, 0, 0, 0.8);
              line-height: 36px;
            }
            .bm_exa_s_desc {
              font-size: 20px;
              font-weight: 400;
              color: #333;
              line-height: 37px;
              -webkit-line-clamp: 3;
            }
          }
        }
      }
    }
    .bm_exa_s_m_btn {
      padding: 0 0 60px;
      .more {
        font-size: 16px;
        font-weight: 400;
        color: #0064eb;
        cursor: pointer;
      }
    }
    .video-dialog {
      width: 60%;
    }
  }
}
</style>
