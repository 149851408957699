var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bm_exa_s_list"},[_c('div',{staticClass:"bm_exa_s_banner"},[_c('div',{staticClass:"bm_exa_s_video"},[(_vm.data.videoCoverUrl)?_c('img',{staticClass:"img",attrs:{"src":_vm.utils.setOssUrl(_vm.data.videoCoverUrl),"width":"100%","height":"100%"}}):_vm._e(),_c('img',{staticClass:"play",attrs:{"src":require("@/assets/images/play.svg"),"alt":"播放"},on:{"click":function($event){_vm.videoShow = true}}})]),_c('div',{staticClass:"bm_exa_s_banner_main"},[_c('p',{staticClass:"title"},[_vm._v(_vm._s(_vm.data.titleOne))]),(_vm.data.titleTwoUrl)?_c('img',{staticClass:"img",attrs:{"src":_vm.utils.setOssUrl(_vm.data.titleTwoUrl)}}):_vm._e()]),_c('div',{staticClass:"bm_exa_s_banner_footer"},[_c('div',{staticClass:"bm_exa_s_group"},[(
            !_vm.isResize &&
              _vm.data &&
              _vm.data.titleDisposition &&
              _vm.data.titleDisposition.length > 4
          )?_c('div',{staticClass:"bm_exa_s_btn left font_family icon-common_arrow_right"}):_vm._e(),_vm._l((_vm.data.titleDisposition || []),function(item,index){return _c('div',{key:index,staticClass:"bm_exa_s_data"},[_c('h2',[(!Object.is(item.promotionType, '文本'))?[_c('CountUp',{attrs:{"endVal":+item.promotionTitle}}),_vm._v(" "+_vm._s(Object.is(item.promotionType, '百分比') ? '%' : '')+" ")]:[_vm._v(" "+_vm._s(item.promotionTitle)+" ")]],2),_c('p',[_vm._v(_vm._s(item.descriptionDetails))])])}),(
            !_vm.isResize &&
              _vm.data &&
              _vm.data.titleDisposition &&
              _vm.data.titleDisposition.length > 4
          )?_c('div',{staticClass:"bm_exa_s_btn font_family icon-common_arrow_right"}):_vm._e()],2)])]),_c('div',{staticClass:"bm_exa_s_box js_bm_exa_s_list"},[_c('p',{staticClass:"bm_exa_s_t"},[_vm._v(_vm._s(_vm.data.titleThreeIntroduce))]),_c('div',{staticClass:"bm_exa_s_cell"},[_c('div',{staticClass:"bm_exa_s_con bm_exa_s_img"},[(_vm.data.videoTwoUrl)?_c('video-player',{ref:"videoTwo",staticClass:"video vjs-custom-skin vjs-big-play-centered",attrs:{"playsinline":true,"options":_vm.playerOptions({
              src: _vm.data.videoTwoUrl
            })},on:{"play":function($event){return _vm.onPlayerPlay($event)}}}):_vm._e()],1),_c('div',{staticClass:"bm_exa_s_con bm_exa_s_text"},[_vm._v(" "+_vm._s(_vm.data.introduceText)+" ")])]),_c('p',{staticClass:"bm_exa_s_t"},[_vm._v("合作故事")]),(_vm.list.length > 0)?_c('ul',{staticClass:"bm_exa_s_ul"},_vm._l((_vm.list),function(item){return _c('li',{key:item.storyId,staticClass:"bm_exa_s_every",class:[_vm.isResize && 'vishide animate__animated'],on:{"click":_vm.handleStoryDetailClick}},[_c('a',{staticClass:"bm_exa_s_link",attrs:{"href":("/example/details?id=" + (item.storyId) + "&moduleType=" + (_vm.moduleType ||
              _vm.modules.moduleType) + "&source=story"),"target":"_blank"}},[_c('div',{staticClass:"bm_exa_s_img_box"},[(item.coverPictureUrl)?_c('img',{staticClass:"bm_exa_s_img",attrs:{"src":_vm.utils.setOssUrl(item.coverPictureUrl),"alt":""}}):_vm._e(),(item.storyVideoUrl)?_c('img',{staticClass:"play",attrs:{"src":require("@/assets/images/play.svg"),"alt":"播放"}}):_vm._e()]),_c('div',{staticClass:"bm_exa_s_details_box"},[_c('p',{staticClass:"bm_exa_s_title",attrs:{"title":item.storyTitle}},[_vm._v(" "+_vm._s(item.storyTitle)+" ")]),_c('p',{staticClass:"bm_exa_s_desc",attrs:{"title":item.storyAbstract}},[_vm._v(" "+_vm._s(item.storyAbstract)+" ")])])])])}),0):_c('Nothing',{staticClass:"nothing"})],1),_c('div',{staticClass:"bm_exa_s_m_btn"},[(!_vm.isCurPageTotal)?_c('a',{staticClass:"more",on:{"click":_vm.handlePages}},[_vm._v("加载更多")]):_vm._e()]),(_vm.isResize)?_c('FreeBenchmark'):_vm._e(),_c('van-Dialog',{staticClass:"video-dialog",attrs:{"show-confirm-button":false,"close-on-click-overlay":""},model:{value:(_vm.videoShow),callback:function ($$v) {_vm.videoShow=$$v},expression:"videoShow"}},[(_vm.data.videoOneUrl)?_c('video-player',{ref:"videoOne",staticClass:"video vjs-custom-skin vjs-big-play-centered",attrs:{"playsinline":true,"options":_vm.playerOptions({
          src: _vm.data.videoOneUrl
        })},on:{"play":function($event){return _vm.onPlayerPlay($event)}}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }